import request from '@/utils/request'

export default {
  addDialyExpense(data) {
    return request({
      method: 'post',
      url: 'expense/daily',
      data
    })
  },
  upload(file) {
    const form = new FormData()
    form.append('image', file)
    return request({
      method: 'post',
      url: 'expense/upload',
      data: form,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  list(params) {
    return request({
      method: 'get',
      url: 'expense/daily',
      params
    })
  },
  delete(data) {
    return request({
      method: 'post',
      url: 'expense/daily-revoke',
      data
    })
  }
}
