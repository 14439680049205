<template>
  <div class="expense-container">
    <my-nav-bar
      title="日常开支"
      left-text="返回"
      left-arrow
      right-text="我的开支"
      @click-right="toListView"
    />
    <div class="expense-content-wrap">
      <div class="expense-content">
        <van-cell title="门店" :value="storeName" is-link center @click="showStoreActions = true" />
        <van-cell title="日期" :value="saveCurrentDate" is-link center @click="showDateChooseDialog = true" />
        <van-cell class="expense-cell" title="类型" center title-class="expense-type-text" value-class="expense-type-choose">
          <van-radio-group v-model="type" class="expense-cell__radio" direction="horizontal">
            <van-radio icon-size="0.45rem" :name="2">买菜</van-radio>
            <van-radio icon-size="0.45rem" :name="3">加油、车费用</van-radio>
            <van-radio icon-size="0.45rem" :name="4">工资</van-radio>
            <van-radio icon-size="0.45rem" :name="5">运费</van-radio>
            <van-radio icon-size="0.45rem" :name="9">其它</van-radio>
          </van-radio-group>
        </van-cell>
        <van-cell class="expense-cell" title="支付方式" center title-class="expense-type-text" value-class="expense-type-choose">
          <van-radio-group v-model="mode" class="expense-cell__radio" direction="horizontal">
            <van-radio icon-size="0.45rem" :name="1">现金</van-radio>
            <van-radio icon-size="0.45rem" :name="2">微信</van-radio>
            <van-radio icon-size="0.45rem" :name="3">转账</van-radio>
          </van-radio-group>
        </van-cell>
        <van-cell class="expense-pic">
          <van-grid :column-num="3" square :border="false" :gutter="10">
            <van-grid-item v-for="(image, index) in images" :key="index">
              <div class="van-uploader__preview-delete" @click="deleteImage(index)">
                <i class="van-icon van-icon-cross van-uploader__preview-delete-icon" />
              </div>
              <van-image width="2rem" height="2.1rem" :src="image.content" fit="cover" />
            </van-grid-item>
            <van-grid-item v-if="images.length < 6" @click="showActionSheet = true">
              <div class="expense-upload">
                <van-image width="0.9rem" :src="require('@/assets/images/tianjiatupian.png')" />
                <div class="expense-upload__text">上传图片</div>
              </div>
            </van-grid-item>
          </van-grid>
          <!-- 手动调用 -->
          <van-uploader
            ref="uploader"
            v-model="images"
            style="display: none"
            :after-read="afterRead"
            :preview-full-image="false"
            :preview-image="false"
            :max-size="1024 * 1024 * 3"
            :capture="capture"
            @oversize="onOversize"
          />
        </van-cell>
        <van-field
          v-model="description"
          autosize
          label="内容"
          type="textarea"
          maxlength="255"
          placeholder="请输入内容"
          show-word-limit
        />
        <van-field v-model="amount" class="change-van-field-css" :border="false" type="number" label="金额(元)" placeholder="请输入金额" />
        <van-button class="expense-submit-btn" type="primary" round block text="确认提交" @click="handleSubmit" />
      </div>
    </div>
    <van-action-sheet v-model="showActionSheet" :actions="actions" @select="onSelect" />
    <van-action-sheet v-model="showStoreActions" :actions="storeActions" @select="onSelectStore" />
    <van-popup v-model="showDateChooseDialog" position="bottom" :style="{ height: '40%' }">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择日期"
        @confirm="handleConfirmChoose"
        @cancel="showDateChooseDialog = false"
      />
    </van-popup>
  </div>
</template>

<script>
import dialyExpenseApi from '@/api/dialy_expense'
import myNavBar from '@/components/my-nav-bar'
import { toDateString } from '@/utils'
import { getStores } from '@/api/home'
export default {
  name: 'Expense',
  components: { myNavBar },
  data() {
    return {
      type: '',
      description: '',
      amount: '',
      mode: this.$store.state.userInfo.type === 1 ? 2 : 1,
      images: [],
      showActionSheet: false,
      capture: null,
      store_id: this.$store.state.userInfo.store_id,
      actions: [
        { name: '拍照', value: 1 },
        { name: '相册', value: 2 }
      ],
      storeActions: [],
      uploadImages: [],
      currentDate: new Date(),
      saveCurrentDate: null,
      showDateChooseDialog: false,
      showStoreActions: false,
      storeName: ''
    }
  },
  created() {
    getStores().then(res => {
      res.data.map(store => {
        this.storeActions.push({
          value: store.id,
          name: store.name
        })
        if (store.id === Number(this.store_id)) {
          this.storeName = store.name
        }
      })
    })
  },
  methods: {
    onSelect(action) {
      if (action.value === 1) { this.capture = 'camera' } else { this.capture = null }
      this.showActionSheet = false
      this.$nextTick(() => {
        this.$refs.uploader.chooseFile()
      })
    },
    onSelectStore(action) {
      this.store_id = action.value
      this.storeName = action.name
      this.showStoreActions = false
    },
    afterRead(file) {
      this.beginLoad()
      dialyExpenseApi.upload(file.file).then(res => {
        this.endLoad()
        this.uploadImages.push(res.data.image)
      })
    },
    deleteImage(index) {
      this.images.splice(index, 1)
      this.uploadImages.splice(index, 1)
    },
    onOversize() {
      this.fail('文件大小不能超过3M')
    },
    handleSubmit() {
      if (this.type === '') {
        this.fail('请选择开支类型')
        return
      }

      if (this.type === 9 && this.description.length === 0) {
        this.fail('请填写开支内容')
        return
      }

      if (Number(this.amount) <= 0) {
        this.fail('报销金额必须大于0')
        return
      }

      const data = {
        type: this.type,
        images: this.uploadImages,
        description: this.description,
        amount: this.amount,
        date: this.saveCurrentDate,
        mode: this.mode,
        store_id: this.store_id
      }
      this.beginLoad()
      dialyExpenseApi.addDialyExpense(data).then(res => {
        this.success(res.msg)
        this.saveCurrentDate = null
        this.currentDate = new Date()
        this.images = []
        this.type = ''
        this.amount = ''
        this.description = ''
        this.mode = this.$store.state.userInfo.type === 1 ? 2 : 1
      })
    },
    toListView() {
      this.$router.push({
        path: '/finance-expense-list',
        query: {
          store_id: this.store_id
        }
      })
    },
    handleConfirmChoose(value) {
      this.saveCurrentDate = toDateString(value)
      this.currentDate = value
      this.showDateChooseDialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .expense-container{
    .choose-date-cell{
      position: fixed;
      z-index: 999;
      left: 0;
      right: 0;
      top: 46px;
    }
  }
  .expense-content-wrap{
    margin-top: 46px;
    .expense-content {
      padding: 15px;
      .expense-type-text{
        flex: 0;
        span{
          width: 115px;
          display: inline-block;
        }
      }
      .expense-type-choose{
        // flex: 0;
        flex-wrap: nowrap;
        .expense-cell__radio{
          justify-content: flex-start;
        }
      }
      .change-van-field-css{
        .van-field__value{
          display: flex;
        }
      }
    }
  }
  .expense-cell {
    &__radio{
      justify-content: flex-end;
    }
  }
  .expense-pic {
    padding: 15px 5px 5px 5px;
    background-color: #fff;
    .van-grid-item {
      &__content {
        background-color: #F7F7F7;
        border-radius: 4px;
      }
    }
  }
  .expense-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__text {
      padding-top: 5px;
      font-size: 12px;
      color: #646566;
    }
  }
  .expense-submit-btn {
    margin-top: 10px;
  }
</style>
