var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "expense-container"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": "日常开支",
      "left-text": "返回",
      "left-arrow": "",
      "right-text": "我的开支"
    },
    on: {
      "click-right": _vm.toListView
    }
  }), _c('div', {
    staticClass: "expense-content-wrap"
  }, [_c('div', {
    staticClass: "expense-content"
  }, [_c('van-cell', {
    attrs: {
      "title": "门店",
      "value": _vm.storeName,
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        _vm.showStoreActions = true;
      }
    }
  }), _c('van-cell', {
    attrs: {
      "title": "日期",
      "value": _vm.saveCurrentDate,
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        _vm.showDateChooseDialog = true;
      }
    }
  }), _c('van-cell', {
    staticClass: "expense-cell",
    attrs: {
      "title": "类型",
      "center": "",
      "title-class": "expense-type-text",
      "value-class": "expense-type-choose"
    }
  }, [_c('van-radio-group', {
    staticClass: "expense-cell__radio",
    attrs: {
      "direction": "horizontal"
    },
    model: {
      value: _vm.type,
      callback: function callback($$v) {
        _vm.type = $$v;
      },
      expression: "type"
    }
  }, [_c('van-radio', {
    attrs: {
      "icon-size": "0.45rem",
      "name": 2
    }
  }, [_vm._v("买菜")]), _c('van-radio', {
    attrs: {
      "icon-size": "0.45rem",
      "name": 3
    }
  }, [_vm._v("加油、车费用")]), _c('van-radio', {
    attrs: {
      "icon-size": "0.45rem",
      "name": 4
    }
  }, [_vm._v("工资")]), _c('van-radio', {
    attrs: {
      "icon-size": "0.45rem",
      "name": 5
    }
  }, [_vm._v("运费")]), _c('van-radio', {
    attrs: {
      "icon-size": "0.45rem",
      "name": 9
    }
  }, [_vm._v("其它")])], 1)], 1), _c('van-cell', {
    staticClass: "expense-cell",
    attrs: {
      "title": "支付方式",
      "center": "",
      "title-class": "expense-type-text",
      "value-class": "expense-type-choose"
    }
  }, [_c('van-radio-group', {
    staticClass: "expense-cell__radio",
    attrs: {
      "direction": "horizontal"
    },
    model: {
      value: _vm.mode,
      callback: function callback($$v) {
        _vm.mode = $$v;
      },
      expression: "mode"
    }
  }, [_c('van-radio', {
    attrs: {
      "icon-size": "0.45rem",
      "name": 1
    }
  }, [_vm._v("现金")]), _c('van-radio', {
    attrs: {
      "icon-size": "0.45rem",
      "name": 2
    }
  }, [_vm._v("微信")]), _c('van-radio', {
    attrs: {
      "icon-size": "0.45rem",
      "name": 3
    }
  }, [_vm._v("转账")])], 1)], 1), _c('van-cell', {
    staticClass: "expense-pic"
  }, [_c('van-grid', {
    attrs: {
      "column-num": 3,
      "square": "",
      "border": false,
      "gutter": 10
    }
  }, [_vm._l(_vm.images, function (image, index) {
    return _c('van-grid-item', {
      key: index
    }, [_c('div', {
      staticClass: "van-uploader__preview-delete",
      on: {
        "click": function click($event) {
          return _vm.deleteImage(index);
        }
      }
    }, [_c('i', {
      staticClass: "van-icon van-icon-cross van-uploader__preview-delete-icon"
    })]), _c('van-image', {
      attrs: {
        "width": "2rem",
        "height": "2.1rem",
        "src": image.content,
        "fit": "cover"
      }
    })], 1);
  }), _vm.images.length < 6 ? _c('van-grid-item', {
    on: {
      "click": function click($event) {
        _vm.showActionSheet = true;
      }
    }
  }, [_c('div', {
    staticClass: "expense-upload"
  }, [_c('van-image', {
    attrs: {
      "width": "0.9rem",
      "src": require('@/assets/images/tianjiatupian.png')
    }
  }), _c('div', {
    staticClass: "expense-upload__text"
  }, [_vm._v("上传图片")])], 1)]) : _vm._e()], 2), _c('van-uploader', {
    ref: "uploader",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "after-read": _vm.afterRead,
      "preview-full-image": false,
      "preview-image": false,
      "max-size": 1024 * 1024 * 3,
      "capture": _vm.capture
    },
    on: {
      "oversize": _vm.onOversize
    },
    model: {
      value: _vm.images,
      callback: function callback($$v) {
        _vm.images = $$v;
      },
      expression: "images"
    }
  })], 1), _c('van-field', {
    attrs: {
      "autosize": "",
      "label": "内容",
      "type": "textarea",
      "maxlength": "255",
      "placeholder": "请输入内容",
      "show-word-limit": ""
    },
    model: {
      value: _vm.description,
      callback: function callback($$v) {
        _vm.description = $$v;
      },
      expression: "description"
    }
  }), _c('van-field', {
    staticClass: "change-van-field-css",
    attrs: {
      "border": false,
      "type": "number",
      "label": "金额(元)",
      "placeholder": "请输入金额"
    },
    model: {
      value: _vm.amount,
      callback: function callback($$v) {
        _vm.amount = $$v;
      },
      expression: "amount"
    }
  }), _c('van-button', {
    staticClass: "expense-submit-btn",
    attrs: {
      "type": "primary",
      "round": "",
      "block": "",
      "text": "确认提交"
    },
    on: {
      "click": _vm.handleSubmit
    }
  })], 1)]), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.actions
    },
    on: {
      "select": _vm.onSelect
    },
    model: {
      value: _vm.showActionSheet,
      callback: function callback($$v) {
        _vm.showActionSheet = $$v;
      },
      expression: "showActionSheet"
    }
  }), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.storeActions
    },
    on: {
      "select": _vm.onSelectStore
    },
    model: {
      value: _vm.showStoreActions,
      callback: function callback($$v) {
        _vm.showStoreActions = $$v;
      },
      expression: "showStoreActions"
    }
  }), _c('van-popup', {
    style: {
      height: '40%'
    },
    attrs: {
      "position": "bottom"
    },
    model: {
      value: _vm.showDateChooseDialog,
      callback: function callback($$v) {
        _vm.showDateChooseDialog = $$v;
      },
      expression: "showDateChooseDialog"
    }
  }, [_c('van-datetime-picker', {
    attrs: {
      "type": "date",
      "title": "选择日期"
    },
    on: {
      "confirm": _vm.handleConfirmChoose,
      "cancel": function cancel($event) {
        _vm.showDateChooseDialog = false;
      }
    },
    model: {
      value: _vm.currentDate,
      callback: function callback($$v) {
        _vm.currentDate = $$v;
      },
      expression: "currentDate"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }